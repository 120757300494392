import React, { useState, useEffect } from "react";
import { BiUser } from "react-icons/bi";
import { AiOutlineMail } from "react-icons/ai";
import { BsFillTelephoneFill } from "react-icons/bs";
import { IoMdChatbubbles } from "react-icons/io";
import axios from "axios";
import { useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import ImageSkeleton from "./ImageSkeleton";

function HouseDetails() {
  const [loading, setLoading] = useState(false);
  const [commentLoading, setCommentLoading] = useState(false);
  const [selectedDrawing, setSelectedDrawing] = useState([]);
  const { id } = useParams();

  const [formFields, setFormFields] = useState({
    fullName: "",
    emailAddress: "",
    phoneNumber: "",
    comment: "",
  });

  const runOnStart = async () => {
    setLoading(true);
    await axios
      .get(
        `https://perfect-shelters-backend.onrender.com/drawing/get-drawings/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        console.log("chidi", response.data.data);
        setSelectedDrawing(response.data.data);
        localStorage.setItem("drawing_id", response.data.data._id);
      })
      .catch((err) => {
        console.log(err);
        // toast.error(err.message);
      });

    setLoading(false);
  };
  useEffect(() => {
    runOnStart();
    // console.log("abu", drawing);
  }, []);
  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setFormFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const submit = async (e) => {
    console.log("new form", formFields);
    e.preventDefault();

    setCommentLoading(true);

    const drawingId = localStorage.getItem("drawing_id");
    const finalData = {
      ...formFields,
      drawingId,
    };
    console.log("finalData", finalData);
    try {
      const response = await axios.post(
        "https://perfect-shelters-backend.onrender.com/comment/add-comment",
        finalData
      );

      if (response.status === 200) {
        console.log(response);
        runOnStart();
        toast.success("Comment Posted");
        setFormFields({
          fullName: "",
          emailAddress: "",
          phoneNumber: "",
          comment: "",
        });
      } else {
        console.log(`Form submission failed with status ${response}`);
        toast.error("Form submission failed", response);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setCommentLoading(false);
      setFormFields({
        fullName: "",
        emailAddress: "",
        phoneNumber: "",
        comment: "",
      });
    }
  };
  return (
    <div className="md:px-10 mt-20 px-4 font-Montserrat">
      <ToastContainer />
      {loading ? (
        <div className="min-h-screen">
          <ImageSkeleton />
        </div>
      ) : (
        <div>
          <div className="flex md:flex-row flex-col w-full gap-4">
            <div className=" mb-10  md:w-1/2 w-full min-h-[30vh]">
              <div className="shadow-md rounded-md md:p-2 p-4">
                <img
                  alt=""
                  src={selectedDrawing?.frontElevation}
                  className="w-full rounded-md"
                />
                <span className="uppercase font-bold mt-5 md:text-[16px] text-lg flex flex-col">
                  <p>{selectedDrawing?.description}</p>{" "}
                </span>
              </div>
              <div className="shadow-md rounded-md md:p-2 p-4 mt-10 md:grid md:grid-cols-2 grid-cols-1 hidden h-[53vh] overflow-y-scroll">
                {selectedDrawing?.drawing_details?.map((drawing, index) => (
                  <div key={index} className="">
                    <h5 className="uppercase font-bold  md:text-[16px] text-md mb-5">
                      {drawing.floor}
                    </h5>
                    <div className="flex flex-col text-sm md:text-md">
                      {drawing.details.split(",").map((item, subIndex) => (
                        <p
                          key={subIndex}
                          className="uppercase  md:text-[14px] text-sm"
                        >
                          {item}
                        </p>
                      ))}
                    </div>
                  </div>
                ))}
                <span className="flex flex-row items-center mt-10">
                  <p className="uppercase font-bold md:text-lg text-sm">
                    plan price : #
                  </p>
                  <p className="font-bold md:text-lg text-sm ml-2">
                    {selectedDrawing?.price}
                  </p>
                </span>
                <span className="flex flex-row items-center mt-2">
                  <p className="uppercase font-bold md:text-lg text-sm">
                    Drawing Ref No :
                  </p>
                  <p className="font-bold md:text-lg ml-2 text-sm">
                    {selectedDrawing?.refNo}
                  </p>
                </span>

                <button className="font-bold text-[#D0A546] hover:text-black rounded-[32px] border-[#D0A546] border-[1px] py-2 px-6 mt-4">
                  <a href="https://paystack.com/pay/3q8cj7d502">BUY PLAN</a>
                </button>
              </div>
            </div>
            <div className="flex flex-col  gap-10 md:w-1/2 w-full">
              <div className="shadow-md rounded-md md:p-2 p-4  w-full">
                <img
                  alt=""
                  src={selectedDrawing?.leftElevation}
                  className="rounded-md"
                />
                <h5 className="uppercase font-bold mt-5 md:text-[16px] text-lg ">
                  left elevation
                </h5>
              </div>
              <div className="shadow-md rounded-md md:p-2 p-4  w-full md:h-[53vh]">
                <img alt="" src={selectedDrawing?.rightElevation} />
                <h5 className="uppercase font-bold mt-5 md:text-[16px] text-lg rounded-md">
                  right elevation
                </h5>
              </div>
            </div>
            <div className="shadow-md rounded-md md:p-2 p-4 mt-10 grid md:grid-cols-2 grid-cols-1 md:hidden ">
              {selectedDrawing?.drawing_details?.map((drawing, index) => (
                <div key={index} className="">
                  <h5 className="uppercase font-bold  md:text-[16px] text-md mb-5">
                    {drawing.floor}
                  </h5>
                  <div className="flex flex-col text-sm md:text-md">
                    {drawing.details.split(",").map((item, subIndex) => (
                      <p
                        key={subIndex}
                        className="uppercase  md:text-[14px] text-sm"
                      >
                        {item}
                      </p>
                    ))}
                  </div>
                </div>
              ))}
              <span className="flex flex-row items-center mt-10">
                <p className="uppercase font-bold md:text-lg text-sm">
                  plan price : #
                </p>
                <p className="font-bold md:text-lg text-sm ml-2">
                  {selectedDrawing?.price}
                </p>
              </span>
              <span className="flex flex-row items-center mt-2">
                <p className="uppercase font-bold md:text-lg text-sm">
                  Drawing Ref No :
                </p>
                <p className="font-bold md:text-lg ml-2 text-sm">
                  {selectedDrawing?.refNo}
                </p>
              </span>

              <button className="font-bold text-[#D0A546] hover:text-black rounded-[32px] border-[#D0A546] border-[1px] py-2 px-6 mt-4">
                <a href="https://paystack.com/pay/3q8cj7d502">BUY PLAN</a>
              </button>
            </div>
          </div>
        </div>
      )}
      {selectedDrawing?.comments && selectedDrawing?.comments?.length > 0 && (
        <div className="shadow-md rounded-b-md md:p-8 p-4 mt-10">
          <p className="uppercase font-bold md:text-lg text-sm">Comments</p>

          {selectedDrawing?.comments?.map((comment, index) => (
            <div key={index} className="mt-4 flex flex-row">
              <p className="font-bold uppercase md:text-md text-sm">
                {comment.fullName}:
              </p>
              <p className="pl-5 pt-3">{comment.comment}</p>
            </div>
          ))}
        </div>
      )}

      <div className="h-full  flex items-center justify-center z-5 md:px-10 py-10  md:py-20 border-2 border-solid rounded-b-md mt-10 bg-gray-500">
        <div className=" p-4 rounded-md  md:w-3/4">
          <form onSubmit={submit}>
            <div className="items-center text-center text-white">
              <h5 className="font-bold md:text-3xl text-xl red">
                LEAVE A COMMENT
              </h5>
              <p className="red">Let us know how we may be of help.</p>
              <p>
                for more information about our services fill in the form and get
                answers to your questions or inquiries.{" "}
              </p>
            </div>
            <div className="md:flex md:pt-10">
              <div className="md:w-1/2 md:pr-3 animate-fadeInLeft duration-1000 ease-in-out inputBox">
                <div className="relative flex items-center">
                  <input
                    onChange={handleFieldChange}
                    type="text"
                    name="fullName"
                    value={formFields.fullName}
                    required
                    placeholder="Full Name"
                    className="text-white w-full px-4 py-3 mb-7 border border-white rounded-md bg-transparent focus:outline-none focus:ring focus:border-blue-500 placeholder-white"
                  />
                  <BiUser className="text-white absolute right-4  transform -translate-y-1/2 text-xl" />
                </div>
                <div className="relative flex items-center">
                  <input
                    onChange={handleFieldChange}
                    type="email"
                    name="emailAddress"
                    value={formFields.emailAddress}
                    required
                    placeholder="Email Address"
                    className="text-white w-full px-4 py-3 mb-7 border border-white rounded-md bg-transparent focus:outline-none focus:ring focus:border-blue-500 placeholder-white"
                  />
                  <AiOutlineMail className="text-white absolute right-4 transform -translate-y-1/2 text-xl" />
                </div>
                <div className="relative flex items-center">
                  <input
                    onChange={handleFieldChange}
                    type="text"
                    name="phoneNumber"
                    value={formFields.phoneNumber}
                    required
                    placeholder="Phone Number"
                    className="text-white w-full px-4 py-3 mb-7  border border-white rounded-md bg-transparent focus:outline-none focus:ring focus:border-blue-500 placeholder-white"
                  />
                  <BsFillTelephoneFill className="text-white absolute right-4 text-xl transform -translate-y-1/2" />
                </div>
              </div>
              <div className="md:w-1/2 md:pl-3">
                <div className="relative flex items-center justify-center">
                  <textarea
                    name="comment"
                    required
                    value={formFields.comment}
                    onChange={handleFieldChange}
                    className="w-full h-52 px-4 py-2 mb-4 border flex items-center border-white rounded-md bg-transparent text-white placeholder-gray-300 focus:outline-none focus:ring focus:border-blue-500 resize-none"
                    placeholder="Type Your Comment"
                  ></textarea>
                  <IoMdChatbubbles className="text-white absolute right-4  transform -translate-y-1/2 text-xl" />
                </div>
              </div>
            </div>
            <div className="items-center text-center w-full">
              <button
                className=" text-white font-[Poppins] py-2 md:w-2/4 w-full rounded bg-black hover:bg-indigo-400 duration-500 "
                type="submit"
              >
                {commentLoading ? "Loading..." : "POST A COMMENT"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default HouseDetails;
