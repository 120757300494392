import { Route, Routes } from "react-router-dom";
import Homepage from "./pages/homepage";
import BungalowPage from "./pages/bungalowPage";
import DuplexPage from "./pages/duplexPage";
import HouseDetailsPage from "./pages/houseDetailsPage";
import BuyPlansPage from "./pages/buyPlansPage";
import AdminLoginPage from "./pages/adminLoginPage";
import AdminSignupPage from "./pages/adminSignupPage";
import RootLayout from "./components/admin/RootLayout";
import AddDrawing from "./components/admin/AddDrawing";
import PaymentConfirmaationPage from "./pages/paymentConfirmaationPage";
import BungalowCategoryPage from "./pages/bungalowCategoryPage";
import DuplexCategoryPage from "./pages/duplexCategoryPage";
import AllFeaturesPage from "./pages/allFeaturesPage";
import AllProducts from "./components/admin/allProducts";
import EditDrawing from "./components/admin/editDrawing";
import ScrollToTop from "./components/scrollToTop";
import FlatPage from "./pages/flatPage";
import FlatCategoryPage from "./pages/flatCategoryPage";
import AboutUsPage from "./pages/aboutUsPage";
import ConstructionPage from "./pages/constructionPage";
import NonResidentialPage from "./pages/nonResidentialPage";
import AddConstruction from "./components/admin/addConstruction";
import AllConstructs from "./components/admin/allConstructs";
import AddPicture from "./components/admin/addPicture";
import Picture from "./components/Houses/pictures";
import PicturesPage from "./pages/picturesPage";
const App = () => {
  return (
    <div>
      <Routes>
        <Route path="/add-pictures/:id" element={<RootLayout><AddPicture /></RootLayout>} />
        <Route path="/all-constructs" element={<RootLayout><AllConstructs /></RootLayout>} />
        <Route path="/pictures/:id" element={<PicturesPage />} />
        <Route path="/about" element={<AboutUsPage />} />
        <Route path="/non-residential" element={<NonResidentialPage />} />
        <Route path="/" element={<Homepage />} />
        <Route path="/payment-confirmation" element={<PaymentConfirmaationPage />} />
        <Route path="/construction" element={<ConstructionPage />} />
        <Route path="/add-construction" element={<RootLayout><AddConstruction /></RootLayout>} />
        <Route path="/bungalows" element={<BungalowPage />} />
        <Route path="/featured-house-plans" element={<AllFeaturesPage />} />
        <Route path="/bungalow/:category" element={<BungalowCategoryPage />} />
        <Route path="/flat/:category" element={<FlatCategoryPage />} />
        <Route path="/duplex/:category" element={<DuplexCategoryPage />} />
        <Route path="/duplex" element={<DuplexPage />} />
        <Route path="/flat" element={<FlatPage />} />
        <Route path="/house-details/:id" element={<HouseDetailsPage />} />
        <Route path="/buy-plans" element={<BuyPlansPage />} />
        <Route path="/login" element={<AdminLoginPage />} />
        <Route path="/sign-up" element={<AdminSignupPage />} />
        <Route path="/dashboard" element={<RootLayout><AddDrawing /></RootLayout>} />
        <Route path="/all-drawings" element={<RootLayout><AllProducts /></RootLayout>} />
        <Route path="/all-drawings/edit/:id" element={<RootLayout><EditDrawing /></RootLayout>} />
      </Routes>
      <ScrollToTop />

    </div>
  );
}

export default App; 
