import React from "react";
import Navbar from "../components/homepage/Navbar";
import Footer from "../components/homepage/Footer";
import Bungalow from "../components/Houses/Bungalow";

const BungalowPage = () => {
  return (
    <div>
      <Navbar />
      <Bungalow />
      <Footer />
    </div>
  );
};
export default BungalowPage;
