import React from "react";
import interiorDecor from "../../assets/homepage/interiorDecor.jpeg";
import floorPlan from "../../assets/homepage/FloorPlan.jpeg";
import newProject4 from "../../assets/homepage/newProject4.jpeg";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Link } from "react-router-dom";

function Services() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="md:px-10 px-4 font-Montserrat mt-6">
      <div className="flex justify-center">
        <h3 className="md:text-3xl text-xl text-center text-gray-800 my-5 font-bold  pb-1">
          SERVICES WE OFFER!!!
        </h3>
      </div>
      <div className="flex flex-col md:flex-row">
        <div
          className="w-full md:w-1/3 mb-5  shadow-md border-solid border-2 rounded-b-md cursor-pointer"
          data-aos="flip-right"
          action=""
        >
          <Link to="/featured-house-plans">
            <img
              className="w-full h-72 transition-all duration-200 ease-in-out hover:scale-110"
              alt="floor_Plan"
              src={floorPlan}
            />
            <div
              data-aos="zoom-in"
              data-aos-duration="5000"
              className=" text-gray-800 "
            >
              <h4 className="md:text-2xl text-lg text-center mt-4 font-bold">
                Building Plans
              </h4>
              <p className="p-5 text-center font-Poppins">
                As one of the leading Architectural companies in Nigeria, we
                create building plans that stand the test of time.
              </p>
            </div>
          </Link>
        </div>

        <div
          className="w-full md:w-1/3 mb-5 md:mx-10  shadow-md border-solid border-2  rounded-b-md cursor-pointer"
          id="contact"
          data-aos="fade-right"
        >
          <Link to="/construction">
            <img
              className="w-full h-72 transition-all duration-200 ease-in-out hover:scale-110"
              alt="Ongoing Project"
              src={newProject4}
            />
            <div
              data-aos="zoom-in"
              data-aos-duration="5000"
              className=" text-gray-800 "
            >
              <h4 className="md:text-2xl text-lg text-center mt-4 text-gray-800 font-bold">
                Building Construction
              </h4>
              <p className="p-5 text-center font-Poppins">
                We provide complete construction solutions. From multi
                residential estates to single units.
                <span className="font-bold">
                  {" "}
                  Click here to see our Portfolio.
                </span>
              </p>
            </div>
          </Link>
        </div>
        <div
          className="w-full md:w-1/3 md:mb-5  shadow-md border-solid border-2 rounded-b-md cursor-pointer"
          data-aos="flip-right"
          action=""
        >
          <img
            className="w-full h-72 transition-all duration-200 ease-in-out hover:scale-110"
            alt="Decor"
            src={interiorDecor}
          />
          <div
            data-aos="zoom-in"
            data-aos-duration="5000"
            className=" text-gray-800 "
          >
            <h4 className="md:text-2xl text-lg text-center mt-4 text-gray-800 font-bold">
              Interior Decor'
            </h4>
            <p className="p-5 text-center font-Poppins">
              Our team of designers will make your home a paradise with our
              world-class state of the art finishing which is top-notch{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Services;
