import React from 'react';
import Navbar from '../components/homepage/Navbar';
import Footer from '../components/homepage/Footer';
import BuyPlans from '../components/Houses/BuyPlans';


function buyPlansPage() {
  return (
    <div>
        <Navbar/>
        <BuyPlans/>
        <Footer/>
    </div>
  )
}

export default buyPlansPage