import React from 'react';
import HouseDetails from '../components/Houses/HouseDetails';
import Navbar from '../components/homepage/Navbar';
import Footer from '../components/homepage/Footer';

function HouseDetailsPage() {
  return (
    <div>
        <Navbar/>
        <HouseDetails/>
        <Footer/>
    </div>
  )
}

export default HouseDetailsPage