import React, { useState } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import Logo from "../../assets/homepage/perfectSheltersLogo.jpeg";
import { Link } from "react-router-dom";
import { MdKeyboardArrowDown } from "react-icons/md";

const Navbar = () => {
  const [open, setOpen] = useState(false);

  const [isBungalowOpen, setBungalowOpen] = useState(false);
  const [isDuplexOpen, setDuplexOpen] = useState(false);
  const [isFlatOpen, setFlatOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleWindowResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useState(() => {
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const toggleDuplex = () => {
    setDuplexOpen((prevState) => !prevState);
  };
  const toggleFlat = () => {
    setFlatOpen((prevState) => !prevState);
  };
  const toggleBungalow = () => {
    setBungalowOpen((prevState) => !prevState);
  };
  const openDropDown = () => {
    setOpen(!open);
    setBungalowOpen(false);
    setDuplexOpen(false);
    setFlatOpen(false);
  };
  return (
    <div className=" w-full fixed top-0 left-0 z-50 font-Montserrat ">
      <div className="md:flex items-center justify-between bg-white  md:px-10 px-7 py-2 md:py-0 border-[#D0A546] border-b-[1px]">
        <div className="font-bold text-black text-2x1 cursor-pointer flex items-center font-[poppins]">
          <Link to="/">
            <img src={Logo} alt="Logo" className="md:w-28 w-16" />
          </Link>
        </div>
        <div
          onClick={openDropDown}
          className="text-3x1 absolute right-8 top-6 cursor-pointer md:hidden text-black"
        >
          {open ? <AiOutlineClose /> : <AiOutlineMenu />}
        </div>
        <ul
          className={`md:flex md:items-center md:pb-0  absolute md:static bg-white md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-4 transition-all duration-500 ease-in ${
            open ? "top-4 opacity-100" : "top-[-490px] z-[-1]"
          } md:opacity-100 mt-12 md:mt-0`}
        >
          <li className="md:ml-8 text-sm md:my-0 my-7">
            <Link
              className="text-gray-800 hover:text-gray-400 duration-500 cursor-pointer"
              to="/"
            >
              HOME
            </Link>
          </li>
          <li
            className="md:ml-8 text-sm md:my-0 my-7"
            onMouseEnter={!isMobile ? () => setBungalowOpen(true) : null}
            onMouseLeave={!isMobile ? () => setBungalowOpen(false) : null}
          >
            <span className="flex flex-row items-center gap-3 md:gap-0">
              <Link to="/bungalows">
                <span className="text-gray-800 hover:text-gray-400 duration-500 cursor-pointer py-5">
                  BUNGALOWS
                </span>
              </Link>
              <MdKeyboardArrowDown
                className="font-bold text-lg"
                onClick={isMobile ? toggleBungalow : null}
              />
            </span>
            {isBungalowOpen && (
              <div className="relative md:absolute  md:w-44 w-full bg-white rounded-lg shadow-lg z-10 transition-all duration-500 ease-in">
                <ul className="py-2">
                  <Link to="/bungalow/2-bedroom">
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      2 Bedroom Bungalow
                    </li>
                  </Link>
                  <Link to="/bungalow/3-bedroom">
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      3 Bedroom Bungalow
                    </li>
                  </Link>

                  <Link to="/bungalow/4-bedroom">
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      4 Bedroom Bungalow
                    </li>
                  </Link>
                  <Link to="/bungalow/5-bedroom">
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      5 Bedroom Bungalow
                    </li>
                  </Link>
                  <Link to="/bungalow/6-bedroom">
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      6+ Bedroom Bungalow
                    </li>
                  </Link>
                </ul>
              </div>
            )}
          </li>
          <li
            className="md:ml-8 text-sm md:my-0 my-7"
            onMouseEnter={!isMobile ? () => setDuplexOpen(true) : null}
            onMouseLeave={!isMobile ? () => setDuplexOpen(false) : null}
          >
            <span className="flex flex-row items-center gap-3 md:gap-0">
              <Link to="/duplex">
                <span className="text-gray-800 hover:text-gray-400 duration-500 cursor-pointer py-5">
                  DUPLEX
                </span>
              </Link>
              <MdKeyboardArrowDown
                className="font-bold text-lg"
                onClick={isMobile ? toggleDuplex : null}
              />
            </span>
            {isDuplexOpen && (
              <div className="relative md:absolute md:w-44 w-full bg-white rounded-lg shadow-lg z-10 transition-all duration-500 ease-in">
                <ul className="py-2">
                  <Link to="/duplex/2-bedroom">
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      2 Bedroom Duplex
                    </li>
                  </Link>
                  <Link to="/duplex/3-bedroom">
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      3 Bedroom Duplex
                    </li>
                  </Link>
                  <Link to="/duplex/4-bedroom">
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      4 Bedroom Duplex
                    </li>
                  </Link>
                  <Link to="/duplex/5-bedroom">
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      5 Bedroom Duplex
                    </li>
                  </Link>
                  <Link to="/duplex/6-bedroom">
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      6+ Bedroom Duplex
                    </li>
                  </Link>
                </ul>
              </div>
            )}
          </li>
          <li
            className="md:ml-8 text-sm md:my-0 my-7"
            onMouseEnter={!isMobile ? () => setFlatOpen(true) : null}
            onMouseLeave={!isMobile ? () => setFlatOpen(false) : null}
          >
            <span className="flex flex-row items-center gap-3 md:gap-0">
              <Link to="/flat">
                <span className="text-gray-800 hover:text-gray-400 duration-500 cursor-pointer py-7">
                  FLATS
                </span>
              </Link>
              <MdKeyboardArrowDown
                className="font-bold text-lg"
                onClick={isMobile ? toggleFlat : null}
              />
            </span>
            {isFlatOpen && (
              <div className="relative md:absolute md:w-44 w-full bg-white rounded-lg shadow-lg z-10 transition-all duration-500 ease-in">
                {/* Dropdown items */}
                <ul className="py-2">
                  <Link to="/flat/2-bedroom">
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      2 Bedroom Flat
                    </li>
                  </Link>
                  <Link to="/flat/3-bedroom">
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      3 Bedroom Flat
                    </li>
                  </Link>
                  <Link to="/flat/4-bedroom">
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      4 Bedroom Flat
                    </li>
                  </Link>
                  <Link to="/flat/5-bedroom">
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      5 Bedroom Flat
                    </li>
                  </Link>
                  <Link to="/flat/6-bedroom">
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      6+ Bedroom Flat
                    </li>
                  </Link>
                </ul>
              </div>
            )}
          </li>

          {/* <li className="md:ml-8 text-sm md:my-0 my-7">
            <span className="text-gray-800 hover:text-gray-400 duration-500 cursor-pointer">
              CUSTOM
            </span>
          </li> */}
          <li className="md:ml-8 text-sm md:my-0 my-7">
            <Link to="/featured-house-plans">
              <span className="text-gray-800 md:py-2 py-1 rounded-[32px] md:px-6 px-2 border-[#D0A546] border-[1px] hover:text-gray-400 duration-500 cursor-pointer">
                BUY PLANS
              </span>
            </Link>
          </li>
          {/* <li className="md:ml-8 text-sm md:my-0 my-7">
            <span className="text-gray-800 hover:text-gray-400 duration-500 cursor-pointer">
              FAQ
            </span>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
