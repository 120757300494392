import axios from "axios";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const AllConstructs = () => {
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState({});
  const [drawings, setDrawings] = useState([]);
  const runOnStart = async () => {
    setLoading(true);
    await axios
      .get(
        `https://perfect-shelters-backend.onrender.com/construct/get-all-construction`,
        // `http://localhost:8080/drawing/get-drawings`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        console.log("chidi", response.data.data);
        // setAppointments([...response.data.data.data]);
        setDrawings([...response.data.data]);
      })
      .catch((err) => {
        console.log(err);
        // toast.error(err.message);
      });

    setLoading(false);
  };
  useEffect(() => {
    runOnStart();
  }, []);
  const handleDeleteProduct = async (productId) => {
    setDeleteLoading((prevLoading) => ({
      ...prevLoading,
      [productId]: true, // Set loading state for the specific document to true
    }));
    try {
      // Make an HTTP request to delete the product
      const response = await axios.delete(
        `https://perfect-shelters-backend.onrender.com/construct/delete-construction/${productId}`
        // `http://localhost:8080/construct/delete-construction/${productId}`
      );

      // Check if the deletion was successful
      if (response.status === 200) {
        // Optionally, update your local state or perform any other actions
        console.log("Product deleted successfully!");
        toast.success("Product deleted successfully!");
        setTimeout(() => {
          runOnStart();
        }, 2000);
      }
    } catch (error) {
      console.log("Error deleting product:", error);
      toast.error("Error deleting product:", error);
      // Handle errors appropriately
    } finally {
      setDeleteLoading((prevLoading) => ({
        ...prevLoading,
        [productId]: true, // Set loading state for the specific document to true
      }));
    }
  };
  return (
    <div className="px-4">
      <ToastContainer />{" "}
      <div className="md:grid-cols-4 grid grid-cols-3 md:gap-4 gap-2 md:mt-5 mt-2">
        {drawings.map((drawing, index) => (
          <div key={index}>
            <div className=" flex flex-col p-4 rounded-md shadow-md pb-5 bg-white">
              <img
                src={drawing?.coverPhoto}
                className="h-[180px] w-full"
                style={{ objectFit: "contain" }}
                alt=""
              />
              <p className="md:font-bold text-[10px] md:text-[15px]">
                {drawing?.title}
              </p>

              <span className="flex flex-col md:flex-row justify-between gap-2 mt-2">
                <Link
                  to={`/add-pictures/${drawing?._id}`}
                  className="py-2 px-4 bg-blue-600 md:w-[50%] w-full rounded-md "
                >
                  Edit
                </Link>
                <button
                  className="p-2 md:w-[50%] w-full bg-slate-500 rounded-md"
                  onClick={() => handleDeleteProduct(drawing?._id)}
                >
                  {deleteLoading[drawing?._id] ? "Deleting..." : "Delete"}
                </button>
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllConstructs;
