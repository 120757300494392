import React, { useState } from "react";
import { Upload, message, Form, Button } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
const { Dragger } = Upload;
const Dashboard = () => {
  const [formFields, setFormFields] = useState({
    frontElevation: "",
    rightElevation: "",
    leftElevation: "",
    type: "",
    category: "",
    description: "",
    refNo: "",
    price: "",
    drawing_details: [{ floor: "", details: "" }],
  });
  const [uploadedFrontElevation, setUploadedFrontELevation] = useState(null);
  const [uploadedRightElevation, setUploadedRightELevation] = useState(null);
  const [uploadedLeftElevation, setUploadedLeftELevation] = useState(null);
  const [frontElevationFileName, setFrontElevationFileName] = useState("");
  const [rightElevationFileName, setRightElevationFileName] = useState("");
  const [leftElevationFileName, setLeftElevationFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const addInputField = () => {
    setFormFields((prevFields) => ({
      ...prevFields,
      drawing_details: [
        ...prevFields.drawing_details,
        { floor: "", details: "" },
      ],
    }));
  };
  const handleInputChange = (e, index, key) => {
    const newInputFields = [...formFields.drawing_details];
    newInputFields[index][key] = e.target.value;

    // Update the dynamic input fields using setFormFields
    setFormFields((prevFields) => ({
      ...prevFields,
      drawing_details: newInputFields,
    }));

    // Use updateFields to update the entire form data
  };
  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setFormFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const handleFrontElevationUpload = (file) => {
    setUploadedFrontELevation(file);

    if (file.file.status === "done") {
      const response = file.file.response;

      if (response && response.success) {
        // Handle successful upload
        const uploadedFileId = response.data[0].bucket.data.url;
        setFormFields((prevFormFields) => ({
          ...prevFormFields,
          frontElevation: uploadedFileId,
        }));
        setFrontElevationFileName(file.file.name);
        message.success(`${file.file.name} file uploaded successfully`);
        toast.success("File uploaded successfully");
      } else {
        // Handle upload failure
        setUploadedFrontELevation(null); // Reset uploaded file state
        setFrontElevationFileName(""); // Reset file name
        toast.error("File upload failed");
        message.error(`${file.file.name} file upload failed.`);
      }
    } else if (file.file.status === "error") {
      // Handle upload failure (e.g., network issue)
      setUploadedFrontELevation(null); // Reset uploaded file state
      setFrontElevationFileName(""); // Reset file name
      toast.error("File upload failed (network issue)");
      message.error(`${file.file.name} file upload failed (network issue).`);
    }
  };
  const handleRightElevationUpload = (file) => {
    setUploadedRightELevation(file);

    if (file.file.status === "done") {
      const response = file.file.response;

      if (response && response.success) {
        // Handle successful upload
        const uploadedFileId = response.data[0].bucket.data.url;
        setFormFields((prevFormFields) => ({
          ...prevFormFields,
          rightElevation: uploadedFileId,
        }));
        setRightElevationFileName(file.file.name);
        message.success(`${file.file.name} file uploaded successfully`);
        toast.success("File uploaded successfully");
      } else {
        // Handle upload failure
        setUploadedRightELevation(null); // Reset uploaded file state
        setRightElevationFileName(""); // Reset file name
        toast.error("File upload failed");
        message.error(`${file.file.name} file upload failed.`);
      }
    } else if (file.file.status === "error") {
      // Handle upload failure (e.g., network issue)
      setUploadedRightELevation(null); // Reset uploaded file state
      setRightElevationFileName(""); // Reset file name
      toast.error("File upload failed (network issue)");
      message.error(`${file.file.name} file upload failed (network issue).`);
    }
  };
  const handleLeftElevationUpload = (file) => {
    setUploadedLeftELevation(file);

    if (file.file.status === "done") {
      const response = file.file.response;

      if (response && response.success) {
        // Handle successful upload
        console.log("nazzi", response);
        const uploadedFileId = response.data[0].bucket.data.url;
        setFormFields((prevFormFields) => ({
          ...prevFormFields,
          leftElevation: uploadedFileId,
        }));
        setLeftElevationFileName(file.file.name);
        message.success(`${file.file.name} file uploaded successfully`);
        toast.success("File uploaded successfully");
      } else {
        // Handle upload failure
        setUploadedLeftELevation(null); // Reset uploaded file state
        setLeftElevationFileName(""); // Reset file name
        toast.error("File upload failed");
        message.error(`${file.file.name} file upload failed.`);
      }
    } else if (file.file.status === "error") {
      // Handle upload failure (e.g., network issue)
      setUploadedLeftELevation(null); // Reset uploaded file state
      setLeftElevationFileName(""); // Reset file name
      toast.error("File upload failed (network issue)");
      message.error(`${file.file.name} file upload failed (network issue).`);
    }
  };
  const frontElevationFile = {
    name: "file",
    maxCount: 1,
    action: `https://perfect-shelters-backend.onrender.com/bucket/upload`,
    onChange: handleFrontElevationUpload,
    onDrop(e) {},
  };
  const rightElevationFile = {
    name: "file",
    maxCount: 1,
    action: `https://perfect-shelters-backend.onrender.com/bucket/upload`,
    onChange: handleRightElevationUpload,
    onDrop(e) {},
  };
  const leftElevationFile = {
    name: "file",
    maxCount: 1,
    action: `https://perfect-shelters-backend.onrender.com/bucket/upload`,
    onChange: handleLeftElevationUpload,
    onDrop(e) {},
  };

  const submit = async () => {
    console.log("new form", formFields);
    // e.preventDefault();

    setIsLoading(true);

    try {
      const response = await axios.post(
        "https://perfect-shelters-backend.onrender.com/drawing/add-drawing",
        formFields
      );

      if (response.status === 200) {
        console.log(response);
        toast.success("Drawing submitted successfully");
        window.location.reload()
        setUploadedLeftELevation(null);
        setUploadedRightELevation(null);
        setUploadedFrontELevation(null);
        setFormFields(""); // Set loading state to false when the form submission completes (success or error)
      } else {
        console.log(`Form submission failed with status ${response}`);
        toast.error("Form submission failed", response);
      }
    } catch (error) {
      console.error(error.response.data.message);
      toast.error(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="mt-10 px-10 ">
      <ToastContainer />
      <Form className="gap-10 flex flex-col" onFinish={submit}>
        <Form.Item
          name="file1"
          label="Front Elevation"
          rules={[{ required: true, message: "File 1 is required" }]}
          labelCol={{ span: 24 }}
        >
          <Dragger {...frontElevationFile} className="relative border-none">
            {uploadedFrontElevation ? (
              <>
                <div className="justify-between flex flex-row px-5 py-5">
                  <span className="text-[24px] font-[400] text-center">
                    {frontElevationFileName ? (
                      <span className="flex flex-row gap-4">
                        <img src="/gallery.svg" width="32" height="32" alt="" />
                        {frontElevationFileName}
                      </span>
                    ) : (
                      <span>Uploading...</span>
                    )}
                  </span>
                </div>
              </>
            ) : (
              <>
                <div className="text-center mb-2">
                  <img
                    src={"/doc.svg"}
                    width={40}
                    height={40}
                    alt="doc icon"
                    className="mx-auto"
                  />
                  <p className="ant-upload-hint mb-3">Upload pictures</p>
                  <p className="ant-upload-hint">
                    Videos must be less than 30mb
                  </p>
                  <p className="ant-upload-hint">
                    Pictures must be less than 2mb
                  </p>
                  <p className="ant-upload-hint">in size</p>
                </div>

                <div className="flex justify-center items-center relative mt-5">
                  <div className="bg-[#12B97D] py-3 px-7 rounded-full flex justify-center items-center absolute top-0">
                    <p className="text-[#fafafa]">Upload</p>
                  </div>
                </div>
              </>
            )}
          </Dragger>
        </Form.Item>
        <Form.Item
          name="file1"
          label="Right Elevation"
          rules={[{ required: true, message: "File 1 is required" }]}
          labelCol={{ span: 24 }}
        >
          <Dragger className="relative border-none" {...rightElevationFile}>
            {uploadedRightElevation ? (
              <>
                <div className="justify-between flex flex-row px-5 py-5">
                  <span className="text-[24px] font-[400] text-center">
                    {rightElevationFileName ? (
                      <span className="flex flex-row gap-4">
                        <img src="/gallery.svg" width="32" height="32" alt="" />
                        {rightElevationFileName}
                      </span>
                    ) : (
                      <span>Uploading...</span>
                    )}
                  </span>
                </div>
              </>
            ) : (
              <>
                <div className="text-center mb-2">
                  <img
                    src={"/doc.svg"}
                    width={40}
                    height={40}
                    alt="doc icon"
                    className="mx-auto"
                  />
                  <p className="ant-upload-hint mb-3">Upload pictures</p>
                  <p className="ant-upload-hint">
                    Videos must be less than 30mb
                  </p>
                  <p className="ant-upload-hint">
                    Pictures must be less than 2mb
                  </p>
                  <p className="ant-upload-hint">in size</p>
                </div>

                <div className="flex justify-center items-center relative mt-5">
                  <div className="bg-[#12B97D] py-3 px-7 rounded-full flex justify-center items-center absolute top-0">
                    <p className="text-[#fafafa]">Upload</p>
                  </div>
                </div>
              </>
            )}
          </Dragger>
        </Form.Item>
        <Form.Item
          name="file1"
          label="Left Elevation"
          rules={[{ required: true, message: "File 1 is required" }]}
          labelCol={{ span: 24 }}
        >
          <Dragger className="relative border-none" {...leftElevationFile}>
            {uploadedLeftElevation ? (
              <>
                <div className="justify-between flex flex-row px-5 py-5">
                  <span className="text-[24px] font-[400] text-center">
                    {leftElevationFileName ? (
                      <span className="flex flex-row gap-4">
                        <img src="/gallery.svg" width="32" height="32" alt="" />
                        {leftElevationFileName}
                      </span>
                    ) : (
                      <span>Uploading...</span>
                    )}
                  </span>
                </div>
              </>
            ) : (
              <>
                <div className="text-center mb-2">
                  <img
                    src={"/doc.svg"}
                    width={40}
                    height={40}
                    alt="doc icon"
                    className="mx-auto"
                  />
                  <p className="ant-upload-hint mb-3">Upload pictures</p>
                  <p className="ant-upload-hint">
                    Videos must be less than 30mb
                  </p>
                  <p className="ant-upload-hint">
                    Pictures must be less than 2mb
                  </p>
                  <p className="ant-upload-hint">in size</p>
                </div>

                <div className="flex justify-center items-center relative mt-5">
                  <div className="bg-[#12B97D] py-3 px-7 rounded-full flex justify-center items-center absolute top-0">
                    <p className="text-[#fafafa]">Upload</p>
                  </div>
                </div>
              </>
            )}
          </Dragger>
        </Form.Item>
        <div className=" flex flex-col md:flex-row gap-4">
          <Form.Item className="md:w-[50%] w-full">
            <select
              name="type"
              value={formFields.type}
              onChange={handleFieldChange}
              className="w-full h-[50px] border-[1.5px] bg-[#E8E8E8] rounded px-5 outline-none"
            >
              <option value="">Choose Type</option>
              <option value="bungalow">Bungalow</option>
              <option value="duplex">Duplex</option>
              <option value="flat">Flat</option>
              <option value="nonResidential">Non-Residential</option>
            </select>
          </Form.Item>
          <Form.Item className="md:w-[50%] w-full">
            <select
              name="category"
              value={formFields.category}
              onChange={handleFieldChange}
              className="w-full h-[50px] border-[1.5px] bg-[#E8E8E8] rounded px-5 outline-none"
            >
              <option value="">Choose Category</option>
              <option value="2-bedroom">2 Bedroom</option>
              <option value="3-bedroom">3 Bedroom</option>
              <option value="4-bedroom">4 Bedroom</option>
              <option value="5-bedroom">5 Bedroom</option>
              <option value="6-bedroom">6+ Bedroom</option>
              <option value="nonResidential">Non Residential</option>
            </select>
          </Form.Item>
        </div>
        <div className=" flex flex-col md:flex-row gap-4">
          <Form.Item className="md:w-[50%] w-full">
            <input
              required
              type="text"
              id="description"
              name="description"
              value={formFields.description}
              placeholder="Enter Description"
              onChange={handleFieldChange}
              className="mt-1 p-2 rounded-md w-full bg-[#E8E8E8] focus:ring-indigo-500 focus:border-indigo-500"
            />
          </Form.Item>
          <Form.Item className="md:w-[50%] w-full">
            <input
              required
              type="text"
              id="refNo"
              name="refNo"
              value={formFields.refNo}
              placeholder="Enter Ref No"
              onChange={handleFieldChange}
              className="mt-1 p-2  bg-[#E8E8E8] rounded-md w-full focus:ring-indigo-500 focus:border-indigo-500"
            />
          </Form.Item>
        </div>
        <div className=" flex flex-col md:flex-row gap-4">
          <Form.Item className="md:w-[50%] w-full">
            <input
              required
              type="text"
              id="price"
              name="price"
              placeholder="Enter Price"
              value={formFields.price}
              onChange={handleFieldChange}
              className="mt-1 p-2 rounded-md w-full bg-[#E8E8E8] focus:ring-indigo-500 focus:border-indigo-500"
            />
          </Form.Item>
        </div>
        <Form.Item>
          {formFields.drawing_details?.map((field, index) => (
            <div
              key={index}
              className="w-full h-fit flex gap-4 flex-col md:flex-row"
            >
              <div className="md:w-[50%] w-full flex flex-col gap-3 my-3">
                <label
                  htmlFor={`floor-${index}`}
                  className="text-[#434344] font-nunito font-medium"
                >
                  Floor
                </label>
                <input
                  type="text"
                  placeholder={`Floor ${index + 1}`}
                  value={field.floor}
                  name={`drawing_details[${index}].floor`}
                  onChange={(e) => handleInputChange(e, index, `floor`)}
                  className="w-full h-[50px] border-[1.5px] border-[#E8E8E8] rounded indent-5 outline-none"
                />
              </div>
              <div className="md:w-[50%] w-full flex flex-col gap-3 my-3">
                <label
                  htmlFor={`address-${index}`}
                  className="text-[#434344] font-nunito font-medium"
                >
                  Details
                </label>
                <textarea
                  type="text"
                  placeholder={`Details ${index + 1}`}
                  value={field.details}
                  name={`drawing_details[${index}].details`}
                  onChange={(e) => handleInputChange(e, index, "details")}
                  className="w-full h-[50px] border-[1.5px] border-[#E8E8E8] rounded indent-5 outline-none"
                ></textarea>
              </div>
            </div>
          ))}
        </Form.Item>
        <Form.Item>
          <button
            className="bg-[#12B97D] p-3 rounded-md"
            onClick={addInputField}
            type="button"
          >
            Add more floor Details
          </button>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="text-black">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Dashboard;
