/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { Upload, message, Form, Button } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useParams,useNavigate } from "react-router-dom";
const { Dragger } = Upload;
const EditDrawing = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [drawings, setDrawings] = useState([]);
  const [formFields, setFormFields] = useState({
    category: "",
    description: "",
    price: "",
    quantity: "",
    shipping: "",
    estimatedDelivery: "",
    condition: "",
  });
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setFormFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };
  const runOnStart = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://perfect-shelters-backend.onrender.com/drawing/get-drawings/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const productData = response.data.data; // Assuming data is an array and you want the first item

        // Set the initial values for the form fields
        setFormFields({
          category: productData?.category,
          description: productData?.description,
          price: productData?.price,
          quantity: productData?.quantity,
          shipping: productData?.shipping,
          estimatedDelivery: productData?.estimatedDelivery,
          condition: productData?.condition,
        });

        setDrawings(productData);
      } else {
        console.log(
          `Failed to fetch product data with status ${response.status}`
        );
        toast.error("Failed to fetch product data");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
      console.log("drawing", drawings);
    }
  };
  useEffect(() => {
    runOnStart();
    console.log("drawing", drawings);
  }, []);
  const submit = async () => {
    console.log("new form", formFields);
    // e.preventDefault();

    setIsLoading(true);
    let finalData = {
      category: formFields.category,
      description: formFields.description,
      price: formFields.price,
      quantity: formFields.quantity,
      shipping: formFields.shipping,
      estimatedDelivery: formFields.estimatedDelivery,
      condition: formFields.condition,
    };
    try {
      const response = await axios.post(
        `https://ferdi-be.onrender.com/products/edit-product/${id}`,
        finalData
      );

      if (response.status === 200) {
        console.log(response);
        toast.success("Product Edited successfully");
        navigate("/all-products");
        // Set loading state to false when the form submission completes (success or error)
      } else {
        console.log(`Form submission failed with status ${response}`);
        toast.error("Form submission failed", response);
      }
    } catch (error) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="mt-10 px-10 ">
      <ToastContainer />
      <Form className="gap-10 flex flex-col" onFinish={submit}>
        <div className=" flex flex-col md:flex-row gap-4">
          <Form.Item
            className="md:w-[50%] w-full"
            label="Quantity"
            rules={[{ required: true, message: "Front Elevation is required" }]}
            labelCol={{ span: 24 }}
          >
            <input
              required
              type="text"
              id="quantity"
              name="quantity"
              value={formFields.quantity}
              placeholder="Enter Quantity"
              onChange={handleFieldChange}
              className=" p-2 rounded-md w-full bg-[#E8E8E8] focus:ring-indigo-500 focus:border-indigo-500"
            />
          </Form.Item>
          <Form.Item
            className="md:w-[50%] w-full"
            label="Category"
            rules={[{ required: true, message: "Front Elevation is required" }]}
            labelCol={{ span: 24 }}
          >
            <select
              name="category"
              value={formFields.category}
              onChange={handleFieldChange}
              className="w-full h-[50px] border-[1.5px] bg-[#E8E8E8] rounded px-5 outline-none"
            >
              <option value="">Choose Category</option>
              <option value="2 bedroom">2 Bedroom</option>
              <option value="3 bedroom">3 Bedroom</option>
              <option value="4 bedroom">4 Bedroom</option>
              <option value="5 bedroom">5 Bedroom</option>
              <option value="6 Bedroom">6+ Bedroom</option>
            </select>
          </Form.Item>
        </div>
        <div className=" flex flex-col md:flex-row gap-4">
          <Form.Item
            className="md:w-[50%] w-full"
            label="Description"
            rules={[{ required: true, message: "Description is required" }]}
            labelCol={{ span: 24 }}
          >
            <input
              required
              type="text"
              id="description"
              name="description"
              value={formFields.description}
              placeholder="Enter Description"
              onChange={handleFieldChange}
              className="mt-1 p-2 rounded-md w-full bg-[#E8E8E8] focus:ring-indigo-500 focus:border-indigo-500"
            />
          </Form.Item>
          <Form.Item
            className="md:w-[50%] w-full"
            label="Price"
            rules={[{ required: true, message: "Price is required" }]}
            labelCol={{ span: 24 }}
          >
            <input
              required
              type="text"
              id="price"
              name="price"
              placeholder="Enter Price"
              value={formFields.price}
              onChange={handleFieldChange}
              className="mt-1 p-2 rounded-md w-full bg-[#E8E8E8] focus:ring-indigo-500 focus:border-indigo-500"
            />
          </Form.Item>
        </div>
        <div className=" flex flex-col md:flex-row gap-4">
          <Form.Item
            className="md:w-[50%] w-full"
            label="Shipping"
            rules={[{ required: true, message: "Shipping is required" }]}
            labelCol={{ span: 24 }}
          >
            <input
              required
              type="text"
              id="shipping"
              name="shipping"
              value={formFields.shipping}
              placeholder="Enter Shipping Fee"
              onChange={handleFieldChange}
              className="mt-1 p-2 rounded-md w-full bg-[#E8E8E8] focus:ring-indigo-500 focus:border-indigo-500"
            />
          </Form.Item>
          <Form.Item
            className="md:w-[50%] w-full"
            label="Condition"
            rules={[{ required: true, message: "Description is required" }]}
            labelCol={{ span: 24 }}
          >
            <select
              name="condition"
              value={formFields.condition}
              onChange={handleFieldChange}
              className="w-full h-[50px] border-[1.5px] bg-[#E8E8E8] rounded px-5 outline-none"
            >
              <option value="">Choose Condition</option>
              <option value="new">New</option>
              <option value="used">Used</option>
            </select>
          </Form.Item>
        </div>
        <div className=" flex flex-col md:flex-row gap-4">
          <Form.Item
            className="md:w-[50%] w-full"
            label="Estimated Time of Delivery"
            rules={[
              {
                required: true,
                message: "Estimated Time of Delivery is required",
              },
            ]}
            labelCol={{ span: 24 }}
          >
            <input
              required
              type="text"
              id="estimatedDelivery"
              name="estimatedDelivery"
              value={formFields.estimatedDelivery}
              placeholder="Enter Estimated Delivery Time"
              onChange={handleFieldChange}
              className="mt-1 p-2 rounded-md w-full bg-[#E8E8E8] focus:ring-indigo-500 focus:border-indigo-500"
            />
          </Form.Item>
        </div>
        <div className=" flex flex-col md:flex-row gap-4"></div>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="text-black">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditDrawing;
