import React from 'react';
import HeroSection from '../components/homepage/HeroSection';
import Navbar from '../components/homepage/Navbar';
import ContactUs from '../components/homepage/ContactUs';
import Services from '../components/homepage/Services';
import FeaturedPlans from '../components/homepage/FeaturedPlans';
import Footer from '../components/homepage/Footer';

function homepage() {
  return (
    <div>
        <Navbar/>
        <HeroSection/>
        <ContactUs/>
        <Services/>
        <FeaturedPlans/>
        <Footer/>
    </div>
  )
}
export default homepage