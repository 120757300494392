import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';



const SignUp = () => {
    const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate()

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
      };

    
  
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
      
        if (!isChecked) {
          toast.error('Please agree to the Privacy Policy and Terms before submitting.');
          setIsLoading(false);
          return;
        }
      
        const formData = new FormData(event.target);
        const formObject = {};
      
        formData.forEach((value, key) => {
          if (key !== 'confirmPassword') {
            formObject[key] = value;
          }
        });
      
        if (formData.get('password') !== formData.get('confirmPassword')) {
          toast.error('Passwords do not match.');
          setIsLoading(false);
          return;
        }
      
        try {
          const response = await axios.post('https://perfect-shelters-backend.onrender.com/users/signup', formObject, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          });
        
          if (response.data.error) {
            // Log the exact error response from the backend
            console.log("Backend Error Response:", response);
            toast.error(response || response);
          } else {
            event.target.reset();
            toast.success("Email verification link sent to your provided email");
            // Delay the navigation by a short time (e.g., 1000 milliseconds or 1 second)
            setTimeout(() => {
              navigate("/user-login");
            }, 3000);
            console.log("Success:", response.data.data.message);
          }
        } catch (error) {
          console.log("Error:", error.response.data.message);
          toast.error(error.response.data.message)
        } finally {
          setIsLoading(false);
        }
        
      };
      
    
  return (
    <div className="flex justify-center bg-gray-300 px-4 h-full">
        <div className='md:w-2/5 w-full bg-white mt-20 mb-12 md:p-10 rounded-md p-4'>
            <ToastContainer className="z-9999"/>
            <p className='font-bold text-lg mb-8'>CREATE AN ACCOUNT</p>
            <form onSubmit={handleSubmit}>
                <div className='flex flex-col md:flex-row gap-2'>
                    <div className='mb-5 md:w-1/2 w-full'>
                        <label htmlFor="firstname" className="block text-sm font-medium text-gray-700">Firstname</label>
                        <input 
                            required
                            type="text" 
                            id="firstName" 
                            name="firstName"
                            className="mt-1 p-2 border rounded-md w-full focus:ring-indigo-500 focus:border-indigo-500"
                        />
                    </div>
                    <div className='mb-5 md:w-1/2 w-full'>
                        <label htmlFor="lastname" className="block text-sm font-medium text-gray-700">Lastname:</label>
                        <input 
                            required
                            type="text" 
                            id="lastName" 
                            name="lastName"
                            className="mt-1 p-2 border rounded-md w-full focus:ring-indigo-500 focus:border-indigo-500"
                        />
                    </div>
                </div>
                <div className='mb-5'>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                    <input 
                        required
                        type="text" 
                        id="email" 
                        name="email"
                        className="mt-1 p-2 border rounded-md w-full focus:ring-indigo-500 focus:border-indigo-500"
                    />
                </div>
                <div className='flex md:flex-row flex-col gap-2 items-center'>
                    <div className='mb-5 md:w-1/2 w-full'>
                        <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Phone</label>

                        <input
                            required
                            type="text"
                            id="phone" 
                            name="phone"
                           
                        className="mt-1 p-2 border rounded-md w-full focus:ring-indigo-500 focus:border-indigo-500"
                            
                        />
                    </div>
                    <div className='mb-5 md:w-1/2 w-full'>
                    <label htmlFor="address" className="block text-sm font-medium text-gray-700">address</label>
                    <input 
                        required
                        type="text" 
                        id="address" 
                        name="address"
                        className="mt-1 p-2 border rounded-md w-full focus:ring-indigo-500 focus:border-indigo-500"
                    />
                </div>
                 
                </div>
              
                <div className='mb-5'>
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password:</label>

                    <input
                        required
                        id="password" 
                        name="password"
                        type="password" // Use type="password" for password fields
                        className="mt-1 p-2 border rounded-md w-full focus:ring-indigo-500 focus:border-indigo-500"
                    />
                </div>
                <div  className='mb-5'>
                    <label htmlFor="Confirmpassword" className="block text-sm font-medium text-gray-700">Confirm Password:</label>

                    <input
                        required
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        className="mt-1 p-2 border rounded-md w-full focus:ring-indigo-500 focus:border-indigo-500"
                    />
                </div>
                <div className='flex flex-row mb-5'>
                    <input
                        required
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        className='mr-2'
                    />
                    <p> I agree with <span className='text-blue-500'> Privacy Policy , Terms and Service</span></p>
                </div>
                    <button className='bg-blue-500 w-full py-2 rounded-md mb-5'>
                        { isLoading ?  "Processing..." : "Create Account" }
                    </button>
                
                <p className='text-center'>Already have an account?<Link to='/user-login'> <span className='text-blue-500'> Login Account</span></Link></p>
            </form>
        </div>
    </div>
  )
}

export default SignUp