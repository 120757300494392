import React, { useState, useEffect } from "react";
import Image1 from "../../assets/homepage/House1.jpeg";
import Image2 from "../../assets/homepage/House2.jpeg";
import Image3 from "../../assets/homepage/House3.jpeg";
import Image4 from "../../assets/homepage/House4.jpeg";
import Image6 from "../../assets/homepage/House6.jpeg";
import Image7 from "../../assets/homepage/House7.jpeg";
import Image8 from "../../assets/homepage/House8.jpeg";
import Image9 from "../../assets/homepage/House9.jpeg";
import Image11 from "../../assets/homepage/House11.jpeg";
import Image12 from "../../assets/homepage/House12.jpeg";
import Image13 from "../../assets/homepage/House14.jpeg";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const slideImages = [
  Image1,
  Image2,
  Image3,
  // Image4,
  Image6,
  Image7,
  Image8,
  Image9,
  Image11,
  Image12,
  Image13,
];

function HeroSection() {
  const spanStyle = {
    padding: "20px",
    background: "#efefef",
    color: "#000000",
  };

  const divStyle = {
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
    // backgroundSize: "cover",
  };

  return (
    <div className="slide-container ">
      <Fade autoplay={true} duration={3000} pauseOnHover={false}>
        {slideImages.map((slideImage, index) => (
          <div key={index}>
            <div
              className="md:mt-16 mt-2 bg-center md:bg-cover bg-contain md:h-[95vh] h-[40vh]"
              style={{
                ...divStyle,
                backgroundImage: `url(${slideImage})`,
                // backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
          </div>
        ))}
      </Fade>
    </div>
  );
}

export default HeroSection;
