import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { IoIosArrowBack } from "react-icons/io";
import { SlSettings } from "react-icons/sl";
import { AiOutlineAppstore } from "react-icons/ai";
import { BsPerson } from "react-icons/bs";
import { useMediaQuery } from "react-responsive";
import { MdMenu } from "react-icons/md";
import { Link, NavLink, useLocation } from "react-router-dom";
import { GrClose } from "react-icons/gr";

function Sidebar() {
  let isTab = useMediaQuery({ query: "(max-width:768px)" });
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(isTab ? false : true);
  const Sidebar_animation = isTab
    ? {
        open: {
          x: 0,
          width: "16rem",
          transition: {
            damping: 40,
          },
        },
        closed: {
          x: -250,
          width: "0",
          transition: {
            damping: 40,
            delay: 0.15,
          },
        },
      }
    : {
        open: {
          width: "16rem",
          transition: {
            damping: 40,
          },
        },
        closed: {
          width: "4rem",
          transition: {
            damping: 40,
          },
        },
      };
  useEffect(() => {
    if (isTab) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, [isTab]);
  useEffect(() => {
    isTab && setIsOpen(false);
  }, [isTab, pathname]);

  return (
    <div>
      <div
        onClick={() => setIsOpen(false)}
        className={`md:hidden fixed inset-0 max-h-screen z-[998] bg-black/50 ${
          isOpen ? "block" : "hidden"
        }`}
      ></div>
      <motion.div
        variants={Sidebar_animation}
        initial={{ x: isTab ? -250 : 0 }}
        animate={isOpen ? "open" : "closed"}
        className="bg-white text-gray shadow-xl z-[999] w-[16rem] max-w-[16rem] 
     h-screen overflow-hidden md:relative fixed"
      >
        <div className="flex items-center gap-2.5 font-medium border-b border-slate-300 py-3 mx-3">
          <img
            src="https://img.icons8.com/color/512/firebase.png"
            alt=""
            width={45}
          />
          <span className="text-xl whitespace-pre">Fireball</span>
          <div className="ml-auto md:hidden" onClick={() => setIsOpen(false)}>
            <GrClose />
          </div>
        </div>

        <div className="flex flex-col h-full">
          <ul className="whitespace-pre px-2.5 text-[0.9rem] py-5 flex flex-col gap-1 font-medium overflow-x-hidden">
            <li>
              <NavLink to="/dashboard" className={"link"}>
                {/* <AiOutlineAppstore size={23}/> */}
                Add Drawing
              </NavLink>
            </li>
            <li>
              <NavLink to="/all-drawings" className={"link"}>
                {/* <BsPerson size={23}/> */}
                Get All Drawing
              </NavLink>
            </li>
            <li>
              <NavLink to="/add-construction" className={"link"}>
                {/* <BsPerson size={23}/> */}
                Add construction
              </NavLink>
            </li>
            <li>
              <NavLink to="/all-constructs" className={"link"}>
                {/* <BsPerson size={23}/> */}
                All Constructs
              </NavLink>
            </li>
          </ul>
        </div>
      </motion.div>
      <div className=" md:hidden bg-red-500 w-full">
        <MdMenu size={25} onClick={() => setIsOpen(true)} />
      </div>
    </div>
  );
}

export default Sidebar;
