import React from "react";
import Sidebar from "./Sidebar";

function RootLayout({ children }) {
  return (
    <div className="md:flex max-h-[100vh] ">
      <Sidebar />
      <main className="w-full overflow-y-scroll">{children}</main>
    </div>
  );
}

export default RootLayout;
