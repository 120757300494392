import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BsWhatsapp, BsInstagram, BsLinkedin } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";

function Footer() {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  useEffect(() => {
    // Function to update the current year
    const updateYear = () => {
      setCurrentYear(new Date().getFullYear());
    };

    // Initial call to update the year immediately
    updateYear();

    // Set interval to update the year every 1 second
    const intervalId = setInterval(updateYear, 1000);

    // Clean up the interval when component unmounts
    return () => clearInterval(intervalId);
  }, []);
  return (
    <div
      className="md:px-24 mt-20 pt-10 "
      style={{ background: "rgb(208,165,70)" }}
    >
      <div className="md:flex-row flex flex-col px-4">
        <div className="md:w-1/2 text-white pr-10 pb-5">
          <h5 className="font-bold md:text-[20px] text-[12px] border-b-2 border-white mb-5">
            NAVGATION MENU
          </h5>
          <ol className="md:space-y-4 space-y-2 md:text-[16px] text-[10px] text-white">
            <li>
              <Link to="/">Home</Link>
            </li>

            <li>
              {" "}
              <Link to="/about">What Makes Us Different</Link>
            </li>

            <li>
              {" "}
              <Link to="/featured-house-plans">Buy Plans Here</Link>
            </li>
            <li>Contact Us</li>
          </ol>
          <div className="w-full mt-4">
            <h5 className="font-bold md:text-xl text-[12px] font-family-poppins pb-2">
              SOCIAL MEDIA
            </h5>
            <div className="flex gap-2">
              <div className="bg-blue-500 h-8 w-8 rounded-full items-center justify-center flex">
                <a
                  href="https://www.facebook.com/macceeengineeringlimited?mibextid=LQQJ4d"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookF className="text-white" />
                </a>
              </div>
              <div className="bg-green-500 h-8 w-8 rounded-full items-center justify-center flex">
                <a href="https://wa.me/+2349033020343">
                  <BsWhatsapp className="text-white" />
                </a>
              </div>
              <div className="bg-red-400 h-8 w-8 rounded-full items-center justify-center flex">
                <a
                  href="https://instagram.com/perfectsheltersconsult?igshid=NTc4MTIwNjQ2YQ=="
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BsInstagram className="text-white" />
                </a>
              </div>
              <div className="bg-blue-600 h-8 w-8 rounded-full items-center justify-center flex">
                <a
                  href="https://linkedin.com/in/mcsylvester-nwoye-5042001b2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BsLinkedin className="text-white" />
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="md:w-1/3 text-white pr-10 pb-5">
          <h5 className="font-bold md:text-lg text-[12px] border-b-2 border-white">
            PRODUCTS
          </h5>
        </div> */}
        <div className="md:w-1/2 text-white pr-10">
          <h5 className="font-bold md:text-lg border-b-2 text-[12px] border-white mb-5">
            OUR SERVICES
          </h5>
          <ol className="text-[10px] md:text-[16px] flex flex-col gap-3">
            <Link to="/featured-house-plans">
              <li>BUILDING PLANS</li>
            </Link>
            <Link to="/construction">
              <li>BUILDING CONSTRUCTION</li>
            </Link>
            <Link to="/bungalows">
              <li>BUNGALOWS</li>
            </Link>
            <Link to="/duplex">
              <li>DUPLEX</li>
            </Link>
            <Link to="/flat">
              <li>FLAT/APARTMENT</li>
            </Link>
            {/* <li>INTERIOR DESIGN</li> */}
            <Link to="/non-residential">
              <li>NON-RESIDENTIAL</li>
            </Link>
          </ol>
        </div>
      </div>
      <div className="text-center text-white font-bold py-5 border-t-2 border-white text-sm md:text-lg flex flex-col">
        <span className="text-[12px]">
          &copy;{currentYear} Perfect Shelters Consults All Copyright Reserved
        </span>
        <h5 className="text-[10px]">
          Designed by{" "}
          <a
            href="https://www.klincodetech.com"
            target="_blank"
            rel="noopener noreferrer"
            className="uppercase text-black "
          >
            Klincodetech
          </a>
        </h5>
      </div>
    </div>
  );
}

export default Footer;
