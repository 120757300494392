import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import House5 from "../../assets/homepage/House5.jpeg";

function ContactUs() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className="md:px-10 px-4 font-Montserrat">
      <div className="flex md:flex-row flex-col mt-10 justify-between items-center md:gap-10">
        <div className="md:w-1/2 w-full flex ">
          <div>
            <p className="font-Poppins font-[600] md:text-[38px] text-[20px] leading-[46px]">
              Do you plan on building?
            </p>
            <p className="font-Lato font-[400] text-[16px] leading-[25px] mt-3 text-justify">
              At Perfect Shelters, our client-driven approach filters through
              all aspects of our business from initial concept and design, to
              materials sourcing and the actual construction work. Creative and
              experienced professionals. Our core skills lie in the areas of
              designing, construction and remodelling buildings for residential
              and commercial clients.
            </p>
            <p className="font-Lato font-[400] text-[16px] leading-[25px] mt-4">
              GUARANTEED BEST BUILDING EXPERIENCE{" "}
            </p>
            <p className="font-Lato font-bold text-[16px] leading-[25px] mt-4">
              {" "}
              CONTACT US ON 09033020343
            </p>
          </div>
        </div>
        <div className="md:w-1/2 w-full flex justify-center">
          <img src={House5} alt="" className="md:h-[500px] h-[300px] w-full" />
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
