import React from "react";

const AboutUs = () => {
  return (
    <div className=" pt-10  font-Montserrat min-h-screen relative">
      <div
        className="bg-[image:var(--image-url)] md:h-[482px] h-[300px] md:mt-6 mt-5 relative"
        style={{ "--image-url": `url(/images/perfectng.svg)` }}
      >
        <p className="absolute md:bottom-32 bottom-10 left-0 right-0 font-[600] md:text-[64px] text-[32px] leading-[20px] text-[#FFFFFF] text-center justify-end">
          WHO ARE WE?
        </p>
      </div>
      <div className="flex justify-center mt-10 font-[400] text-[24px] leading-[30px] font-Montserrat">
        <div className="md:w-3/4 flex flex-col gap-4 px-4 md:px-0 text-justify">
          <p className="">
            Since 2013, Perfect Shelters has built luxury homes in different
            parts of Nigeria . We offer homeowners the perfect blend of African
            and Western architecture, multi-generational floor plans and
            breathtaking living spaces.
          </p>
          <p>
            {" "}
            We take pride in the homes we build, combining the latest design
            trends and over 10 years of building experience. Our in-house, team
            of expert plan managers, builder representatives, and designers work
            together to offer you a multitude of design options and floor plans
            tailored to the African lifestyle.
          </p>
          <p>
            PERFECT SHELTERS seek out the highest grade of local products and we
            use the region’s best vendors and subcontractors to build a home
            that will last for generations. Our dedication to the smallest
            details is just one of the many reasons we are one of the Nigeria’s
            premiere builders.
          </p>
        </div>
      </div>
      <div className="flex md:flex-row flex-col-reverse mt-10 justify-center items-center md:px-10 px-4">
        <div className="md:w-[40%] w-full">
          <div className="flex flex-col ">
            <p className="text-[#D0A546] font-[600] md:text-[58px] text-[28px] leading-[20px] mb-8 font-Poppins">
              OUR MISSION
            </p>
            <p className="font-[400] md:text-[24px] text-[18px] leading-[30px] text-justify">
              To create exceptional luxury homes that blend African and Western
              architecture, exceeding homeowners expectations with impeccable
              craftmanship customer satisfaction.
            </p>
          </div>
        </div>
        <div className="md:w-[60%] w-full">
          <img src="/images/MAskgroup.svg" alt="" />
        </div>
      </div>
      <div className="flex md:flex-row mt-10 flex-col justify-center items-center md:px-10 px-4">
        <div className="md:w-[50%] w-full">
          <img src="/images/MAskgroup2.svg" alt="" />
        </div>
        <div className="md:w-[50%] w-full">
          <div className="flex flex-col mt-8 md:mt-0">
            <p className="text-[#D0A546] font-[600] md:text-[58px] text-[28px] leading-[20px] mb-8 font-Poppins">
              OUR VISION
            </p>
            <p className="font-[400] md:text-[24px] text-[18px] leading-[20px]">
              To be the leading provider of luxury homes in Nigeria.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
