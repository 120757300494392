import React from "react";
import Navbar from "../components/homepage/Navbar";
import Footer from "../components/homepage/Footer";
import FlatCategory from "../components/Houses/FlatCategory";

const FlatCategoryPage = () => {
  return (
    <div>
      <Navbar />
      <FlatCategory />
      <Footer />
    </div>
  );
};

export default FlatCategoryPage;
