import React from "react";
import { Skeleton } from "antd";
import "./ImageSkeleton.css";

const ImageSkeleton = () => {
  return (
    <div className={`flex md:flex-row flex-col gap-4 mt-4`}>
      {" "}
      <Skeleton.Image active />
      <Skeleton.Image active />
      <Skeleton.Image active />
    </div>
  );
};

export default ImageSkeleton;
