import React from "react";
import Navbar from "../components/homepage/Navbar";
import Footer from "../components/homepage/Footer";
import Flat from "../components/Houses/Flat";

const FlatPage = () => {
  return (
    <div>
      <Navbar />
      <Flat />
      <Footer />
    </div>
  );
};

export default FlatPage;
