import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import ImageSkeleton from "../Houses/ImageSkeleton";

const Construction = () => {
  const [loading, setLoading] = useState(false);
  const [drawings, setDrawings] = useState([]);
  const runOnStart = async () => {
    setLoading(true);
    setDrawings([]);
    await axios
      .get(
        `https://perfect-shelters-backend.onrender.com/construct/get-all-construction`
        // `http://localhost:8080/construct/get-all-construction`
      )
      .then((response) => {
        setDrawings([...response.data.data]);
      })
      .catch((err) => {
        console.log(err);
      });

    setLoading(false);
  };
  useEffect(() => {
    runOnStart();
  }, []);
  return (
    <div className="mt-20 font-[poppins] min-h-screen md:px-10 px-4">
      <div className="md:p-5 p-2 rounded-md bg-[#D0A546] mt-20">
        <h3 className="md:text-3xl text-xl text-center text-gray-800 font-bold">
          OUR PORTFOLIO
        </h3>
      </div>
      <div>
        {loading ? (
          <ImageSkeleton />
        ) : (
          <div className="pt-10 grid md:grid-cols-2 gap-8">
            {drawings.map((drawing, index) => (
              <div
                className="flex flex-row border-b-[2px] gap-4 pb-8"
                key={index}
              >
                <div className="w-1/2 flex flex-col justify-center items-center text-center gap-5">
                  <p className="font-Poppins font-[600] md:text-[20px] text-[12px] text-[#373737]">
                    {drawing?.title}
                  </p>
                  <Link to={`/pictures/${drawing?._id}`}>
                    <button className="border-[1px] border-[#D0A546]  md:text-[16px] text-[10px] md:py-2 py-1 md:rounded-[32px] rounded-[12px] md:px-6 px-2 cursor-pointer">
                      View
                    </button>
                  </Link>
                </div>
                <div className="w-1/2">
                  <img
                    src={drawing?.coverPhoto}
                    alt=""
                    className="w-full md:h-[280px] h-[150px] object-cover rounded-xl min-h-full"
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Construction;
