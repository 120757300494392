import React, { useState, useEffect } from "react";
import axios from "axios";

import { useParams } from "react-router-dom";
import ImageSkeleton from "./ImageSkeleton";

const Picture = () => {
  const [loading, setLoading] = useState(false);
  const [drawings, setDrawings] = useState([]);
  const { id } = useParams();
  const runOnStart = async () => {
    setLoading(true);
    setDrawings([]);
    await axios
      .get(
        `https://perfect-shelters-backend.onrender.com/construct/get-construction/${id}`
        // `http://localhost:8080/construct/get-construction/${id}`
      )
      .then((response) => {
        console.log(response.data);
        setDrawings(response.data.data.pictures);
      })
      .catch((err) => {
        console.log(err);
      });

    setLoading(false);
  };
  useEffect(() => {
    runOnStart();
  }, []);

  return (
    <div className="mt-20 font-[poppins] min-h-screen md:px-10 px-4">
      <div className="md:p-5 p-2 rounded-md bg-[#D0A546] mt-20">
        <h3 className="md:text-3xl text-xl text-center text-gray-800 font-bold">
          OUR GALLERY
        </h3>
      </div>
      <div className="">
        <div>
          {loading ? (
            <ImageSkeleton />
          ) : (
            <div>
              {drawings && drawings.length > 0 ? (
                <div className=" grid md:grid-cols-3 md:gap-4 gap-2 grid-cols-2">
                  {drawings.map((drawing, index) => (
                    <div
                      key={index}
                      className="w-full m-0  mt-4 rounded-b-md text-center"
                    >
                      <img
                        className="w-full md:h-72 h-auto object-scale-down"
                        src={drawing.picture}
                        alt=""
                      />

                      <p className="text-center  uppercase pt-2 text-[10px] md:text-[20px]">
                        {drawing.details}
                      </p>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center mt-4 font-bold md:text-3xl text-lg text-black font-[poppins]">
                  No Picture available
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Picture;
