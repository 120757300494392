import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';



function Login() {
    const [isChecked, setIsChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate()

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
      };

    const handleApiError = (error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          console.log("Server Error:", error.response.data.message);
          toast.error(error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          console.log("No Response from Server:", error.request);
          toast.error("No response from the server. Please check your internet connection.");
        } else {
          // Something else happened while setting up the request
          console.error("Request Error:", error.message);
          toast.error(error.message);
        }
      };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
      
        const formData = new FormData(event.target);
        const formObject = {};
        formData.forEach((value, key) => {
          formObject[key] = value;
        });
      
        try {
          const response = await axios.post('https://perfect-shelters-backend.onrender.com/users/login', formObject, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          });
      
          if (response.data.error) {
            console.log("hello", response);
            toast.error(response.data.message);
          } else {
            localStorage.setItem('userToken', response.data.token);
            toast.success(response.data.message);
            navigate("/dashboard");
            console.log(response.data.message);
          }
        } catch (error) {
          console.log(error.response);
            toast.error(error.response);
          handleApiError(error);
        } finally {
          setIsLoading(false);
        }
      };

  return (
    <div className="flex justify-center bg-gray-300 h-screen md:h-[100vh] px-4">
    <div className='md:w-2/5 w-full bg-white mt-20 mb-12 md:p-10 p-4 rounded-md h-[30rem]'>
    <ToastContainer/>
        <p className='font-bold text-lg mb-8'>LOGIN TO YOUR ACCOUNT</p>
        <form onSubmit={handleSubmit}>
           
            <div className='mb-5'>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email:</label>
                <input 
                    required
                    type="text" 
                    id="email" 
                    name="email"
                    className="mt-1 p-2 border rounded-md w-full focus:ring-indigo-500 focus:border-indigo-500"
                />
            </div>
        
            <div className='mb-5'>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password:</label>

                <input
                    required
                    type="password" 
                    id="password" 
                    name="password"
                    className="mt-1 p-2 border rounded-md w-full focus:ring-indigo-500 focus:border-indigo-500"
                />
            </div>
            <div className='flex flex-row justify-between'>
                {/* <div className='flex flex-row mb-5'>
                    <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        className='mr-2'
                    />
                    <p> Keep me Logged in</p>
                </div> */}
                <Link to='/recover-password'>
                    <p className='text-blue-500 mb-5'>Forgot Password?</p>
                </Link>
            </div>
                <button className='bg-blue-500 w-full py-2 rounded-md mb-5'>
                    { isLoading ? "Processing" : "Login Account" }
                </button>
            
        </form>
        <p className='text-center'>Dont have an account?<Link to='/user-signup'> <span className='text-blue-500'> Create Account</span></Link></p>

    </div>
</div>
  )
}

export default Login