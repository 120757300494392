import React from "react";
import BungalowCategory from "../components/Houses/BungalowCategory";
import Navbar from "../components/homepage/Navbar";
import Footer from "../components/homepage/Footer";
const bungalowCategoryPage = () => {
  return (
    <div>
      <Navbar />
      <BungalowCategory />
      <Footer />
    </div>
  );
};

export default bungalowCategoryPage;
