import React from "react";
import AllFeatured from "../components/Houses/AllFeatured";
import Navbar from "../components/homepage/Navbar";
import Footer from "../components/homepage/Footer";

const allFeaturesPage = () => {
  return (
    <div>
      <Navbar />
      <AllFeatured />
      <Footer />
    </div>
  );
};

export default allFeaturesPage;
