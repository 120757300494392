import React from "react";
import Navbar from "../components/homepage/Navbar";
import Footer from "../components/homepage/Footer";
import Duplex from "../components/Houses/Duplex";

function duplexPage() {
  return (
    <div>
      <Navbar />
      <Duplex />
      <Footer />
    </div>
  );
}

export default duplexPage;
