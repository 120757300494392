import React, { useState } from "react";
import { Upload, message, Form, Button } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
const { Dragger } = Upload;
const AddConstruction = () => {
  const [formFields, setFormFields] = useState({
    coverPhoto: "",
    title: "",
  });
  const [uploadedCoverPhoto, setUploadedCoverPhoto] = useState(null);
  const [coverPhotoFileName, setCoverPhotoFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setFormFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const handlecoverPhotoUpload = (file) => {
    setUploadedCoverPhoto(file);

    if (file.file.status === "done") {
      const response = file.file.response;

      if (response && response.success) {
        // Handle successful upload
        const uploadedFileId = response.data[0].bucket.data.url;
        setFormFields((prevFormFields) => ({
          ...prevFormFields,
          coverPhoto: uploadedFileId,
        }));
        setCoverPhotoFileName(file.file.name);
        message.success(`${file.file.name} file uploaded successfully`);
        toast.success("File uploaded successfully");
      } else {
        // Handle upload failure
        setUploadedCoverPhoto(null); // Reset uploaded file state
        setCoverPhotoFileName(""); // Reset file name
        toast.error("File upload failed");
        message.error(`${file.file.name} file upload failed.`);
      }
    } else if (file.file.status === "error") {
      // Handle upload failure (e.g., network issue)
      setUploadedCoverPhoto(null); // Reset uploaded file state
      setCoverPhotoFileName(""); // Reset file name
      toast.error("File upload failed (network issue)");
      message.error(`${file.file.name} file upload failed (network issue).`);
    }
  };

  const coverPhotoFile = {
    name: "file",
    maxCount: 1,
    action: `https://perfect-shelters-backend.onrender.com/bucket/upload`,
    onChange: handlecoverPhotoUpload,
    onDrop(e) {},
  };

  const submit = async () => {
    console.log("new form", formFields);
    // e.preventDefault();

    setIsLoading(true);

    try {
      const response = await axios.post(
        "https://perfect-shelters-backend.onrender.com/construct/add-construction",
        // "http://localhost:8080/construct/add-construction",
        formFields
      );

      if (response.status === 200) {
        console.log(response);
        window.location.reload();
        toast.success("Drawing submitted successfully");
        // Set loading state to false when the form submission completes (success or error)
      } else {
        console.log(`Form submission failed with status ${response}`);
        toast.error("Form submission failed", response);
      }
    } catch (error) {
      console.log(error.response);
      toast.error(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="mt-10 px-10 ">
      <ToastContainer />
      <Form className="gap-10 flex flex-col" onFinish={submit}>
        <Form.Item
          name="file1"
          label="Cover Photo"
          rules={[{ required: true, message: "File 1 is required" }]}
          labelCol={{ span: 24 }}
        >
          <Dragger {...coverPhotoFile} className="relative border-none">
            {uploadedCoverPhoto ? (
              <>
                <div className="justify-between flex flex-row px-5 py-5">
                  <span className="text-[24px] font-[400] text-center">
                    {coverPhotoFileName ? (
                      <span className="flex flex-row gap-4">
                        <img src="/gallery.svg" width="32" height="32" alt="" />
                        {coverPhotoFileName}
                      </span>
                    ) : (
                      <span>Uploading...</span>
                    )}
                  </span>
                </div>
              </>
            ) : (
              <>
                <div className="text-center mb-2">
                  <img
                    src={"/doc.svg"}
                    width={40}
                    height={40}
                    alt="doc icon"
                    className="mx-auto"
                  />
                  <p className="ant-upload-hint mb-3">Upload pictures</p>
                  <p className="ant-upload-hint">
                    Videos must be less than 30mb
                  </p>
                  <p className="ant-upload-hint">
                    Pictures must be less than 2mb
                  </p>
                  <p className="ant-upload-hint">in size</p>
                </div>

                <div className="flex justify-center items-center relative mt-5">
                  <div className="bg-[#12B97D] py-3 px-7 rounded-full flex justify-center items-center absolute top-0">
                    <p className="text-[#fafafa]">Upload</p>
                  </div>
                </div>
              </>
            )}
          </Dragger>
        </Form.Item>
        <div className=" flex flex-col md:flex-row gap-4">
          <Form.Item className="md:w-[50%] w-full">
            <input
              required
              type="text"
              id="title"
              name="title"
              value={formFields.title}
              placeholder="Enter Title"
              onChange={handleFieldChange}
              className="mt-1 p-2  bg-[#E8E8E8] rounded-md w-full focus:ring-indigo-500 focus:border-indigo-500"
            />
          </Form.Item>
        </div>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="text-black">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddConstruction;
