import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ImageSkeleton from "../Houses/ImageSkeleton";
import axios from "axios";
import { truncateText } from "../functions/helpers";

const NonResidential = () => {
  const [loading, setLoading] = useState(false);
  const [drawings, setDrawings] = useState([]);

  const runOnStart = async () => {
    setLoading(true);
    await axios
      .get(
        `https://perfect-shelters-backend.onrender.com/drawing/search-drawings/search?type=nonResidential`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setDrawings([...response.data.data]);
      })
      .catch((err) => {
        console.log(err);
      });

    setLoading(false);
  };
  useEffect(() => {
    runOnStart();
  }, []);
  return (
    <div className="featuredPlans font-Montserrat min-h-screen">
      <div className="md:px-10 pt-10 px-4">
        <div className="md:p-5 p-2 rounded-md bg-[#D0A546] mt-20">
          <h3 className="md:text-3xl text-xl text-center text-gray-800 font-bold">
            NON-RESIDENTIAL HOUSE PLANS
          </h3>
        </div>
        <div>
          {loading ? (
            <ImageSkeleton />
          ) : (
            <div>
              {drawings && drawings.length > 0 ? (
                <div className=" grid md:grid-cols-3 md:gap-4 gap-2 grid-cols-2">
                  {drawings.slice(0, 6).map((drawing, index) => (
                    <div
                      key={index}
                      className="w-full m-0  mt-4 rounded-b-md text-center"
                    >
                      <img
                        className="w-full md:h-72 h-38 transition-all duration-200 ease-in-out "
                        src={drawing.frontElevation}
                        alt=""
                      />
                      <p className="text-center md:hidden block uppercase pt-2 text-[10px] md:text-[20px]">
                        {truncateText(drawing.description)}
                      </p>
                      <p className="text-center hidden md:block uppercase pt-2 text-[10px] md:text-[20px]">
                        {drawing.description}
                      </p>
                      <p className="text-center uppercase md:pt-2 text-[10px] md:text-[22px]">
                        {drawing.refNo}
                      </p>
                      <Link to={`/house-details/${drawing?._id}`}>
                        <button className="md:my-4 my-2 md:py-2 py-1 md:px-6 px-2 rounded-[32px] hover:text-black text-[#D0A546] border-[#D0A546] border-[1px] font-bold text-[8px] md:text-[16px]">
                          View Plan
                        </button>
                      </Link>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center mt-4 font-bold md:text-3xl text-lg text-black font-[poppins]">
                  No Drawing available
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NonResidential;
