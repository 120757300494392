import React from 'react';
import ImageHolder from '../../assets/homepage/029BAB45-D9CE-453B-A5A1-204858325EB2.PNG';
import {FaShoppingCart} from 'react-icons/fa'


function BuyPlans() {
  return (
    <div>
        <div className='md:px-24 mt-28 px-4 flex md:flex-row  flex-col '>
            <div className='border-2 border-solid rounded-b-md p-4 mb-10 md:w-1/3'>
                <img alt='' src={ImageHolder}/>
                <h5 className='uppercase font-bold mt-5 text-xl'>5 bedroom bungalow (rf 5014)</h5>
                <span className='flex flex-row font-bold'>
                    <h5 className='mr-2'>#</h5>
                    <h5>250,000</h5>
                </span>
                <button className='my-8 py-2 px-6 rounded text-white font-bold flex flex-row items-center' style={{background:'rgb(208,165,70)'}}><FaShoppingCart className='text-white mr-2'/>Add to cart</button>
            </div>
            <div className='border-2 border-solid rounded-b-md p-4 mb-10 md:w-1/3 md:mx-4'>
                <img alt='' src={ImageHolder}/>
                <h5 className='uppercase font-bold mt-5 text-xl'>5 bedroom bungalow (rf 5014)</h5>
                <button className='my-8 py-2 px-6 rounded text-white font-bold' style={{background:'rgb(208,165,70)'}}>Add to cart</button>
            </div>
            <div className='border-2 border-solid rounded-b-md p-4 mb-10 md:w-1/3'>
                <img alt='' src={ImageHolder}/>
                <h5 className='uppercase font-bold mt-5 text-xl'>5 bedroom bungalow (rf 5014)</h5>
                <button className='my-8 py-2 px-6 rounded text-white font-bold' style={{background:'rgb(208,165,70)'}}>Add to cart</button>
            </div>
         
        
        </div>
    </div>
  )
}

export default BuyPlans