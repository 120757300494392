import React from 'react';
import Signup from '../components/admin/Signup';

const  AdminSignupPage = () => {
  return (
    <div>
        <Signup/>
    </div>
  )
}

export default AdminSignupPage