import React from "react";
import NonResidential from "../components/Houses/nonResidential";
import Navbar from "../components/homepage/Navbar";
import Footer from "../components/homepage/Footer";

const NonResidentialPage = () => {
  return (
    <div>
      <Navbar />
      <NonResidential />
      <Footer />
    </div>
  );
};

export default NonResidentialPage;
